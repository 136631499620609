import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {


  constructor(private http: HttpClient) { }

  get idCompany(){
    let paths = window.location.pathname.split("/");
    return paths.length > 3 && paths[2] === 'company' ? paths[3] : '';
  }

  getPlans(nameFile) {
    return this.http.get<any>(`assets/json/sidebar_planes/${nameFile}.json`);
  }
}
